import { cmpButtonsUtils } from './../cmp-buttons/cmp-buttons-utils';
import { goToUrl, goToUrlInANewTab, banners, gaTrackSocial, setCookie } from './fn';
import { cmpDialog } from '../cmp-dialog';
const buttonsFunc = {
    messageAccept(data, element, $box, triggerName) {
        const $button = $(element);
        const $message = $button.parents($box);
        if ($message.length) {
          $message.addClass('hidden');
          setTimeout(function() {
            $message.remove();
            if (triggerName) {
              $(document.body).trigger(triggerName);
            }
          }, 500);
          if (data.cookieName) {
            var expires = data.close == 1 ? 1 : 365;
            setCookie(data.cookieName, 1, expires)
          }
          const url = $button.attr('href');
          const target = $button.attr('target');
          if (url) {
              if(target) {
                goToUrlInANewTab(url, target);
              } else {
                goToUrl(url);
              }
            
          }
        }
      },
}
export default {
  request: cmpButtonsUtils.buttons.request,
    requestC: cmpButtonsUtils.buttons.requestC,
    toggleBox: cmpButtonsUtils.buttons.toggleBox,
    tabs:  function(data,element){
        this.currentButton(element, data);
        if(this.options.targetId){
            var $target = $('#' + this.options.targetId);
            if($target.length){
                if($target.data('value') != data.value){
                    $target.data('value', data.value);
                    $target.attr('data-value', data.value);  
                    if(data.apb){
                        $(element).data('apb', false);
                        banners();
                    }
                    if(this.options.cookieName){
                        setCookie(this.options.cookieName, data.value, 365);
                    }
                    if(data.trigger){
                        $(window).trigger(data.trigger);
                    }                
                }
            }
        }
    },
    footerMessageAccept(data, element) {
        buttonsFunc.messageAccept.call(
          this,
          data,
          element,
          '.m1-footer-message',
          'm1-footer-messages'
        );
      },
    dbProfiler: function() {
        var $detail = $("#dbprofilerDetail");

        cmpDialog.content($detail.html(), null, null, 'profiler', function(asdf, qwer, asdff) {
            var me = this,
                $content = this.$contayner.find('.content'),    
                $overlay = $content.find('.overlay');

            $content.on('click', '.copy-raw', function(event) {
                event.stopPropagation();
                var $this = $(this),
                    textarea = $this.find('textarea');

                textarea.select();

                try {
                    var successful = document.execCommand('copy');
                    var msg = 'Copying text command was ' + (successful ? 'successful' : 'unsuccessful');

                    cmpTrayAlert.show(msg, successful ? 'message' : 'error', !successful ? 5 : null);
                } catch (err) {
                    cmpTrayAlert.show('Cannot write to clipboard', 'error', 5);
                }

            });
            $content.on('click', 'h3', function() {
                var $this = $(this);

                $this.parent().toggleClass('open');
                $this.next().slideToggle();
            });
        });
    },
    goToUrl: function(data,element){
        var url = false;
        if(data.url){
            url = data.url;
        }else if($(element).attr('href')){
            url = $(element).attr('href');
        }
        if(url){
            goToUrl(url);
        }
    },
    searchBox: function(data, element, response){
        cmpButtonsUtils.buttons.toggleBox.click.call(this, data, element);
        if(data.toggle){
            $('#search_text').focus();
        }
    },
    updateCaptcha: function(data, element, response){
        if(response && response.constructor === Object && response.status && response.url && response.id){
            var $captch_input = $(element).parents('fieldset').find('[name="captcha[input]"]');
            var $captch_id = $(element).parents('fieldset').find('[name="captcha[id]"]');

            if($captch_input.length && $captch_id.length){
                var $captha = $captch_input.parents('fieldset').find('img');
                if($captha.length){
                    $captha.attr('src', response.url);
                    $captch_input.val('');
                }

                $captch_id.eq(0).val(response.id);
            }
        }
    },
    share: {
        beforeSend: function(data,  element, jqXHR){
            window.open(element.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=' + data.height + ',width=' + data.width);
            let sharePlace;

            if (element.closest('.m1-article-meta')) {
                sharePlace = 'top';
            } else {
                sharePlace = 'bottom'
            }

            gaTrackSocial(data.shareNetwork, 'share', data.shareUrl, sharePlace);
            if(!data.value){
                return false;
            }
            if(!data.extraParams){
                return false;
            }
            
        }
    },
    mailTo:  function(data,  element){
        var href = $(element).attr('href');
        if(href){
            window.location.href = href;
        }
    },
    // cookieAccept: function(data, element){
    //     var $cookie_accept  = $('#cookie_accept');
    //     if($cookie_accept.length){
    //         $cookie_accept.addClass('hide').delay(1000).hide(function(){
    //             $cookie_accept.remove();
    //         });
    //         $.cookie('cookie_accept', 1, { expires: 365, path: "/"});
    //         var url = $(element).attr('href');
    //         if(url){
    //             goToUrl(url);
    //         }
    //     }
    // },
    // privacyAccept: function(data, element){
    //     var $privacy_accept  = $('#privacy_accept');
    //     if($privacy_accept.length){
    //         $privacy_accept.addClass('hide').delay(1000).hide(function(){
    //             $privacy_accept.remove();
    //         });
    //         $.cookie('privacy_accept', 1, { expires: 365, path: "/"});
    //         var url = $(element).attr('href');
    //         if(url){
    //             goToUrl(url);
    //         }
    //     }
    // },
    documentScrollToTop: function(){
        $(document).scrollTop(0);
    },
    cseTypes: function(data, element) {
        this.currentButton(element);
    }
    //requestM: cmpButtonsUtils.buttons.requestM,
    //requestMC: cmpButtonsUtils.buttons.requestMC,
};
